@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");
//unique v3 colors
$v3red: #bc2851;
$v3grey: #4d4d4d;
$v3black: #2e2e2e;
$v3lightGrey: #f3f3f3;
$v3white: #ffffff;

.v3 {
  font-family: "Poppins", sans-serif !important;
  height: 100%;
  * {
    font-size: 13px;
    transition: all 200ms;
  }
  .content {
    @extend .thin-scrollbar;
  }
  .track-cta {
    display: flex;
    flex-direction: row;
    margin: 0px -20px;
    padding: 10px 20px;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    animation: fadeIn 1s ease both;
    animation-iteration-count: 1;
    cursor: pointer;
    .image-container {
      width: 100px;
      height: 100px;
      margin-right: 20px;
      min-width: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-weight: 400;
      > div {
        margin-bottom: 5px;
      }
      .cta-button {
        display: flex;
        align-items: center;
        padding: 3px 6px;
        border-radius: 5px;
        cursor: pointer;
        .cta-button-icon {
          max-width: 15px;
          display: flex;
          margin-right: 5px;
          height: 100%;
          img {
            height: auto;
            width: 100%;
            position: relative;
          }
        }
        transition: all 200ms;
        &:hover {
          transform: translateY(-2px);
          box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  .miniplayer {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    box-shadow: none;
    z-index: 2;
    transition: all 500ms;
    &.show-shadow {
      box-shadow: 0px 5px 10px -10px rgb(0, 0, 0);
      z-index: 2;
    }
    .miniplayer-top,
    .miniplayer-bottom {
      display: flex;
      flex-direction: row;
      font-weight: 400;
      padding: 15px 0 12px;
      &.no-padding-bottom {
        padding-bottom: 0;
      }

      > div,
      span {
        font-size: 14px;
      }
    }
    .miniplayer-top {
      .artist-name {
        font-weight: 700;
        text-transform: uppercase;
      }
      .pipe {
        font-weight: normal;
      }
      span {
        font-weight: 500;
        margin-right: 7px;
      }
    }
    .miniplayer-bottom {
      font-size: 14px;
      align-items: center;
      .controls {
        display: flex;
        align-items: center;
        span {
          margin-right: 15px;
          cursor: pointer;
          transition: all 200ms;
          &:hover,
          &:active {
            filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.6));
          }
        }
        .playPauseicon {
          width: 30px;
        }
      }
      .ticker {
        flex-grow: 1;
        .control-seconds__elapsed,
        .control-seconds__remain {
          time {
            font-size: 11px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .performances {
    font-size: 11px;
  }
  .track-list {
    ul {
      margin: 0px;
      padding: 0px;
    }
    li {
      list-style: none;
    }
    .track-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 15px 20px;
      border-bottom: 1px solid $v3lightGrey;
      position: relative;
      cursor: pointer;
      transition: all 200ms;
      z-index: 0;
      &:hover {
        box-shadow: 0px 5px 10px -10px rgb(0, 0, 0);
        z-index: 1;
        .index {
          overflow: unset;
          max-width: 15px;
        }
      }
      .index {
        margin-right: 5px;
        width: 15px;
        max-width: 0px;
        transition: max-width 200ms;
        overflow: hidden;
        @media only screen and (max-width: 768px) {
          width: 15px;
          min-width: 15px;
          max-width: 15px;
        }
        &.show {
          max-width: 15px;
          overflow: unset;
        }
      }
      .info-track-container {
        width: 100%;
        .info-track {
          display: flex;
          width: 100%;
          font-weight: 300;
          justify-content: space-between;
          .info-track-left {
            display: flex;
            align-items: center;
            span {
              margin-right: 5px;
            }
            .artist-name {
              font-weight: 500;
              white-space: nowrap;
              text-transform: uppercase;
            }
          }
          .info-track-duration {
            align-self: flex-end;
            font-size: 11px;
            font-weight: 400;
            color: $v3grey;
          }
        }
      }
    }
  }
  .container-webplayer {
    background: #fff;
    display: flex;
    flex: 1;
    position: relative;
    flex-direction: column;
    border: 2px solid;
    max-height: 100%;
    height: 100%;
    overflow: hidden;
  }
  .header {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    font-weight: 500;
    line-height: 20px;
    font-size: 13px;
    img {
      margin-right: 5px;
    }
  }
  .control-seconds {
    display: flex;
    flex-direction: row;
    width: 100%;
    &__elapsed,
    &__remain {
      color: $v3grey;
      line-height: 8px;
      min-width: 34px;
    }
    &__elapsed {
      margin-right: 15px;
    }
    &__slider {
      width: 100%;
      position: relative;
      top: -1px;
      margin-right: 15px;
    }
  }
  .theme-slider {
    .rc-slider-rail {
      height: 2px;
    }
    .rc-slider-track {
      height: 2px;
    }
    .rc-slider-handle {
      width: 2px;
      height: 12px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      border-radius: 0px;
      border: none;
      outline: none;
      &:hover {
        border-color: #d0021b;
      }
      &:focus,
      &:active {
        border-color: #d0021b;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }
  .error {
    color: #b40619;
    padding: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
  }
  .footer {
    position: relative;
    width: 100%;
    bottom: 0;
    padding: 15px 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 13px;
  }
  .footer-text {
    font-weight: 500;
    line-height: 20px;
    margin-right: 10px;
    span {
      font-size: 13px;
    }
    a {
      text-decoration: none;
      color: inherit;
    }
  }
  .button-trail {
    border-radius: 5px;
    padding: 3px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: #fff;
    a {
      color: #fff;
      text-decoration: none;
    }
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.5);
    }
  }

  @media only screen and (max-width: 658px) {
    .mini-player {
      height: 84px;
    }
    .content {
      height: 100%;
    }
    .list-of-albums li {
      padding-top: 6px;
      padding-bottom: 6px;
    }
    .mini-player__controls-right {
      margin-top: 10px;
    }
    .footer {
      height: 50px;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      padding: 5px;
      .button-trail {
        margin-top: 0;
        margin-left: 15px;
      }
    }
    .footer-text {
      margin: 0;
      text-align: center;
    }
    .button-trail {
      margin-top: 15px;
    }
  }
}

.v3 {
  .tooltip {
    .tooltiptext {
      width: 200px;
    }
  }
}

#wave-svg {
  .bar-1 {
    animation: loading-bar-morph 3s linear 1s infinite;
    transform-origin: center;
  }
  .bar-2 {
    animation: loading-bar-morph 3s linear 3s infinite;
    transform-origin: center;
  }
  .bar-3 {
    animation: loading-bar-morph 3s linear 2s infinite;
    transform-origin: center;
  }
  .bar-4 {
    animation: loading-bar-morph 3s linear 3s infinite;
    transform-origin: center;
  }
  .bar-5 {
    animation: loading-bar-morph 3s linear 0.5s infinite;
    transform-origin: center;
  }
}
