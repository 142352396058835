.playerPanelIframe {
  width: 100%;
}

/* Make it a marquee */
.marquee {
  width: 100%;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
}

.marquee .box {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
}

/* Make it move */
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
  &.init {
    white-space: pre;
  }
  &.two-lines {
    -webkit-line-clamp: 2;
  }
}

.no-ticker {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.theme-slider {
  .rc-slider,
  .rc-slider-rail,
  .rc-slider-track,
  .rc-slider-handle {
    cursor: pointer;
  }
}

.thin-scrollbar {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
