.iconPausePlay {
  position: relative;
}

.playerIcon {
  cursor: pointer;
  &.loading {
    animation: darken 2s ease-in-out infinite;
  }
}

.iconRepeat {
  height: 20px;
  width: 20px;
  bottom: 4px;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid transparent;
  border-radius: 50%;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
