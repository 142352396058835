.v1 {
  height: 100%;
  .container-webplayer {
    background: #fff;
    display: flex;
    flex: 1;
    position: relative;
    flex-direction: column;
    border: 2px solid #b40619;
    height: 100%;
    overflow: hidden;
  }
  .header {
    height: 40px;
    background: #b40619;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    img {
      margin-right: 5px;
    }
  }
  .content {
    position: absolute;
    top: 156px;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 236px);
    &:hover {
      overflow: auto;
    }
    &.content-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100%;
      top: 0;
      height: 100%;
    }
  }
  .info-album-bottom {
    padding-top: 0px;
    padding-bottom: 20px;
    margin-bottom: 16px;
    box-shadow: inset 0 -1px 0 0 #ffffff;
  }
  .label {
    margin-bottom: 4px;
    color: #9e939c;
    font-size: 14px;
    line-height: 16px;
  }
  .list-of-albums {
    .label {
      margin-bottom: 8px;
    }
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      padding: 16px 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      .index {
        margin-right: 6px;
        font-size: 14px;
        line-height: 20px;
        color: #8f99aa;
        max-width: 20px;
        min-width: 16px;
        text-align: center;
        img {
          width: 16px;
          height: 16px;
        }
      }
      .info-track {
        font-size: 14px;
        line-height: 20px;
        color: #141140;
        display: block;
        span {
          font-weight: bold;
          margin-right: 8px;
          float: left;
        }
        & > * {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      .performances {
        font-size: 14px;
        line-height: 20px;
        color: #8f99aa;
      }
      &.active {
        .info-track {
          color: #b40619;
        }
      }
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      .name {
        color: #141140;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
      }
    }
  }
  .mini-player {
    width: 100%;
    height: 116px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f4f4f4;
    .player {
      position: absolute;
      bottom: 0;
      width: 1px !important;
      height: 1px !important;
    }
    &__controls {
      width: 100%;
      padding: 0 20px;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      img {
        width: 40px;
        height: 40px;
        cursor: pointer;
        display: block;
      }
      &-left {
        display: flex;
        flex-direction: row;
      }
      &-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        padding-left: 5px;
      }
    }
    .iconVolume {
      width: 18px;
      height: 18px;
      margin-right: 15px;
    }
    .iconExpand {
      width: 24px;
      height: 24px;
      margin-left: 25px;
    }
    .iconMore {
      width: 24px;
      height: 24px;
      margin-right: 25px;
    }
    .box-icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      .iconNext {
        margin-right: 10px;
      }
      .iconPrev,
      .iconNext {
        svg {
          fill: inherit;
          path {
            fill: inherit;
          }
        }
        &.disabled {
          opacity: 0.3;
          img {
            cursor: default;
          }
        }
      }
    }
    .iconPausePlay {
      cursor: pointer;
    }
    .song-info {
      color: #141140;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
      &__row {
        display: flex;
        flex-direction: row;
      }
      &__composer {
        font-weight: bold;
        margin-right: 8px;
      }
      &__performances {
        color: #8f99aa;
      }
    }
  }
  .slider-box {
    width: 120px;
    margin-right: 5px;
  }
  .control-seconds {
    display: flex;
    flex-direction: row;
    width: 100%;
    &__elapsed,
    &__remain {
      color: #8f99aa;
      font-size: 14px;
      line-height: 16px;
      min-width: 34px;
    }
    &__elapsed {
      margin-right: 15px;
    }
    &__remain {
      margin-left: 15px;
    }
    &__slider {
      width: 100%;
      position: relative;
      top: -1px;
    }
  }
  .theme-slider {
    .rc-slider-rail {
      background-color: #dcdfe4;
      height: 8px;
    }
    .rc-slider-track {
      background-color: #b40619;
      height: 8px;
    }
    .rc-slider-handle {
      width: 16px;
      height: 16px;
      margin-top: -4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      outline: none;
      &:hover {
        border-color: #d0021b;
      }
      &:focus,
      &:active {
        border-color: #d0021b;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }
  .error {
    color: #b40619;
    padding: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
  }
  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 80px;
    padding-right: 24px;
    background: #f4f4f4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .footer-text {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #b40619;
    margin-right: 32px;

    a {
      text-decoration: none;
      color: inherit;
    }
  }
  .button-trail {
    background: #b40619;
    border-radius: 100px;
    height: 32px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    color: #fff;
    a {
      color: #fff;
      text-decoration: none;
    }
  }

  @media only screen and (max-width: 658px) {
    .mini-player {
      height: 84px;
    }
    .content {
      height: calc(100% - 174px);
      overflow: hidden;
      scrollbar-width: none;
      top: 124px;
      &:hover {
        overflow: hidden;
      }
    }
    .list-of-albums li {
      padding-top: 6px;
      padding-bottom: 6px;
    }
    .mini-player__controls-right {
      margin-top: 10px;
    }
    .content::-webkit-scrollbar {
      display: none;
      width: 0px !important;
      -webkit-appearance: none;
    }
    .footer {
      height: 50px;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      padding: 0;
      .button-trail {
        margin-top: 0;
        margin-left: 15px;
      }
    }
    .footer-text {
      margin: 0;
      text-align: center;
      font-size: 13px;
    }
    .button-trail {
      margin-top: 15px;
    }
    .list-of-albums {
      width: calc(100% + 17px);
      height: 100%;
      overflow-y: scroll;
    }
  }

  

  .theme {
    &-dark {
      &.container,
      &.content {
        background-color: $darkGrey;
        border: none;
        .content {
          background-color: $darkGrey;
          background: linear-gradient($darkGrey, $black);
        }
        .header,
        .footer {
          background-color: $dark;
        }
        .list-of-albums {
          li {
            .info-track {
              color: #8f99aa;
            }
          }
        }
        .mini-player {
          background: $midGrey;
          .song-info {
            color: $white !important;
            font-weight: bold;
          }
          .song-info__performances {
            color: $white;
            font-weight: normal;
          }
        }
      }
    }
    &-light {
      &.container,
      &.content {
        .header,
        .footer {
          background-color: $white;
        }
        .list-of-albums {
          li {
            &.active {
              color: $black !important;
            }
          }
        }
        .mini-player {
          background: $midGrey;
          .song-info {
            color: $white !important;
            font-weight: bold;
          }
          .song-info__performances {
            color: $white;
            font-weight: normal;
          }
        }
      }
    }
    &-blue {
      &.container,
      &.content {
        background-color: $lightBlue;
        .content {
          background: linear-gradient($blue, $veryDarkBlue);
        }
        .header,
        .footer {
          background-color: $veryDarkBlue !important;
          .footer-text {
            color: $white !important;
          }
        }
        .mini-player {
          background: $veryDarkBlue;
          .song-info {
            color: $veryDarkBlue !important;
          }
          .song-info__performances {
            color: $white;
          }
        }
      }
    }
  }
}
