.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;

  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: left;
    padding: 5px;
    border-radius: 6px;
  
    position: absolute;
    top: -5px;
    right: 105%; 
    z-index: 1;

    ul {
      margin: 0;
      padding-left: 10px;
      li {
        margin-bottom: 2px;
        list-style: circle;
      }
    }
  }
  &:hover {
    .tooltiptext{
      visibility: visible;
    }
  }
}
