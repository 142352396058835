@import "./animations.scss";
@import "./constants.scss";
@import "./miniplayer.scss";
@import "./sideBar.scss";
@import "./tooltip.scss";
@import "./common.scss";
@import "./v1.scss";
@import "./v2.scss";
@import "./v3.scss";

* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  font-family: Roboto;
}
#root {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.collapsible {
  padding: 10px 3px;
  border: 1px solid grey;
  border-radius: 5px;
  margin-bottom: 10px;
  .collapsible {
    .collapsible-title {
      font-weight: 500;
    }
  }
  &-header {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: grey;
    margin-bottom: 5px;
    cursor: pointer;
    > div {
      font-size: 12px;
      color: $black;
      font-weight: 700;
    }
  }
  &-body {
    transition: all 500ms;
    max-height: 0px;
    overflow: hidden;
    &.open {
      max-height: 10000px;
      overflow: unset;
    }
  }
}

.content {
  top: 156px;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  &:hover {
    overflow: auto;
  }
  &.content-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    top: 0;
    height: 100vh;
    width: 100%;
  }
}
