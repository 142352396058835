@keyframes darken {
    0% {
      filter: brightness(100%);
    }
    50% {
      filter: brightness(60%);
    }
    100% {
      filter: brightness(100%);
    }
  }

  @keyframes loading-bar-morph {
    0% {
      transform: scaleY(1);
    }
    25% {
      transform: scaleY(0.3);
    }
    50% {
      transform: scaleY(0.7);
    }
    75% {
      transform: scaleY(0.15);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }