.flex-row {
  display: flex;
  flex-direction: row;
  height: inherit;
}

.sideBar {
  max-width: 310px;
  min-width: 310px;
  background-color: $lightGrey;
  padding: 10px;
  overflow-y: scroll;
  &.disabled,
  .disabled {
    pointer-events: none;
    opacity: 0.6;
  }
  @extend .thin-scrollbar;
}
.input-group {
  margin-bottom: 10px;
  .message {
    font-size: 11px;
    display: block;
    text-align: center;
    color: $red;
    margin-top: -10px;
    .message {
      margin-top: -5px;
    }
  }

  .with-prefix {
    display: flex;
    align-items: center;
    span {
      margin-right: 3px;
    }
  }
  .label {
    font-size: 12px;
    color: $black;
    overflow-wrap: break-word;
    word-wrap: break-word;
    &.bold {
      font-weight: bold;
    }
    .tooltip {
      float: right;
    }
  }
  input,
  select {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    outline: none;
    border: 1px solid $midGrey;
    background-color: $white;
  }

  input[type="radio"] {
    width: auto;
  }
}

.divider {
  border-bottom: 0.5px solid $midGrey;
  width: 100%;
  margin: 20px 0;
}

.btn-red {
  flex-grow: 1;
}
.btn-red,
.copy-btn {
  color: $white;
  background-color: $red;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
}
.btn-red {
  &:active {
    filter: brightness(50%);
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    background-color: $midGrey;
    border-color: $red;
  }
}

.copy-btn {
  width: max-content;
  background-color: $white;
  color: $red;
  margin-left: 0;
  border: 1px solid $red;
  transition: all 500ms;
  &:active {
    color: $white;
    background-color: $red;
  }
}

.iframe-embed {
  max-height: 40px;
  overflow-y: auto;
}
