$black: #000000;
$white: #ffffff;

$dark: #0f0f0f;
$darkGrey: #2a2a2a;
$midGrey: #7b7b7b;

$red: #b40619;

$blue: #4866e0;
$darkBlue: #3c52aa;
$lightBlue: #91a5f4;
$veryDarkBlue: #19296c;
$lightGrey: #ececec;
